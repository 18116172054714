import {  Component, ElementRef, HostBinding, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core'
import { take } from 'rxjs/operators'
import { DOCUMENT } from '@angular/common'

import { Subject } from 'rxjs'
import { environment } from 'src/environments/environment'

// Services
import { ConfigurationService } from './services/configuration.service'
import { AnalyticsService } from './services/analytics.service'
import { FaviconService } from './services/favicon.service'
import { TagManagerService } from './services/tag-manager.service'
import { CanonicalUrlService } from './services/canonical-url.service'
import { SchemaAppService } from './services/schema-app.service'
import { ReCaptchaService } from './services/recaptcha.service'
import { LogService } from './services/log.service'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
    private ngUnsubscribe = new Subject<void>()
    @ViewChild('outlet') outlet: ElementRef

    public styles: string
    public footer: string
    public header: string
    public colorPrimary: string
    public isLoaded: boolean

    @HostBinding('style') bodyStyles
    @HostBinding('class') appClasses
    @HostBinding('attr.data-release') dataVersion = environment.version

    constructor(
        private configurationService: ConfigurationService,
        private canonicalUrlService: CanonicalUrlService,
        private analyticsService: AnalyticsService,
        private faviconService: FaviconService,
        private tagManagerService: TagManagerService,
        private schemaAppService: SchemaAppService,
        private recaptchaService: ReCaptchaService,
        private renderer: Renderer2,
        @Inject(Window) private window,
        @Inject(DOCUMENT) private document: HTMLDocument
    ) { }

    ngOnInit() {
        this.configurationService.$configuration.pipe(take(1)).subscribe( () => {
            // Load custom fonts
            if (!!this.configurationService.configuration.catalog.fonts) {
                this.configurationService.configuration.catalog.fonts.forEach((font) => {
                    const url = font.url.replace('{{assetsUrl}}', environment.data.assetsUrl)
                    const newFont = new FontFace(font.name, `url(${url})`)
                    newFont.load().then((res) => {
                        document.fonts.add(res)
                    }).catch((err) => {
                        console.error('Error loading font.', err)
                    })
                })
            }

            // Handle the initial configuration, already preloaded on the APP_INITIALIZER
            if (this.configurationService.configuration.custom) {

                if (this.configurationService.configuration.custom.css) {
                    this.styles = this.configurationService.configuration.custom.css
                }

                if (this.configurationService.configuration.custom.footer) {
                    this.footer = this.configurationService.configuration.custom.footer
                }

                if (this.configurationService.configuration.custom.header) {
                    this.header = this.configurationService.configuration.custom.header
                }

            }
            // Primary color used for the loading bar on top
            this.colorPrimary = this.configurationService.getConfig('catalog.color_primary', '#16b1f3')

            // Handle specific client favicon
            if( this.configurationService.getConfig('catalog.use_favicon') ) {
                this.faviconService.init( this.configurationService.getConfig('catalog.favicon', null) )
            }

            // Handle local styles loading
            // Styles for local  will be under /assets/styles/{client_code}/styles.css
            if (this.configurationService.getConfig('catalog.use_local_styles')) {
                this.renderer.addClass(this.document.body, 'is-loading')
                const link: HTMLLinkElement = this.document.createElement('link')
                const clientCode = this.configurationService.getConfig('catalog.client_code', '').toLowerCase()
                const timestamp = this.configurationService.getConfig('catalog.timestamp', '0')
                link.setAttribute('rel', 'stylesheet')
                link.setAttribute('href', `${environment.data.assetsUrl}${clientCode}/styles/styles.css?v=${timestamp}`)
                link.onload = () => {
                    this.isLoaded = true
                    this.renderer.removeClass(this.document.body, 'is-loading')
                }
                this.document.head.appendChild(link)
            } else {
                this.isLoaded = true
            }

            // Applies css classes to the main angular app outlet
            this.appClasses = this.configurationService.getConfig('catalog.app_css_classes', [])

            // Loads canonical url
            this.canonicalUrlService.init()

            // Loads Heap analytics
            const heapKey = this.window.heap ? this.configurationService.getConfig( 'catalog.heap_key') : false
            if( heapKey ) {
                this.window.heap.load( heapKey )
            }

            // Loads Google analytics
            const gaCode: Array<{ name: string, id: string }> = this.configurationService.getConfig( 'catalog.google_analytics')
            const globalGACode = environment.googleAnalytics.trackingId
            if( gaCode ){
                this.analyticsService.init( gaCode )
            }
            this.analyticsService.init( [{ name: 'global', id: globalGACode }] )

            // Loads ReCaptcha V3
            this.recaptchaService.init()

            // Loads google tag manager
            const gtmCode = this.configurationService.getConfig( 'catalog.google_tag_manager')
            if(gtmCode) {
                this.tagManagerService.init(gtmCode)
            }

            // Fire global page events
            const pageEvents = this.configurationService.getConfig('catalog.gtm_global_page_events')
            if(pageEvents) {
                pageEvents.forEach((event: [string, string, object]) => {
                    LogService.log(`Firing gtag with params: ${JSON.stringify(event)}`, 'info')
                    this.window.gtag(...event)
                })
            }

            // Loads schema app tags
            const schemaAppConfig = this.configurationService.getConfig('catalog.seo.schema_app', null)
            if (schemaAppConfig) {
                this.schemaAppService.init(schemaAppConfig)
            }
        })
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe.next()
        this.ngUnsubscribe.complete()
    }
}
